import React from "react";
import FooterDegin from "../../Component/Footer/FooterDegin";
import Index from "../../Component/Home/Index";


function HomeDegine() {
  return (
    <>
     <Index/>
      <FooterDegin />
    </>
  );
}

export default HomeDegine;
