import React from 'react'
import Fram from "../../assets/Img/Fram.png"
function PartsHeadComp() {
  return (
    <div id="Partsimgbox">
        <img src={Fram} alt='fram'/>
    </div>
  )
}

export default PartsHeadComp