import React from 'react'
import ScreenShot1 from "../../assets/Img/Screenshot81.png"
import ScreenShot2 from "../../assets/Img/Screenshot91.png"

function LabLower() {
  return (
    <div id="LabLowerSection">
        <img src={ScreenShot1} className='img-fluid'  alt='no-img'/>
        <img src={ScreenShot2} className='img-fluid' alt='no-img'/>

    </div>
  )
}

export default LabLower