import Indu1 from "../../assets/Img/indu1.png"
import Indu2 from "../../assets/Img/indu2.png"
import Indu3 from "../../assets/Img/indu3.png"
import Indu4 from "../../assets/Img/indu4.png"
import Indu5 from "../../assets/Img/indu5.png"
import Indu6 from "../../assets/Img/indu6.png"
import Indu7 from "../../assets/Img/indu7.png"
import Indu8 from "../../assets/Img/indu8.png"
import Indu9 from "../../assets/Img/indu9.png"



const info = [
    {
        Name: "HVAC",
        Image: Indu1,
        alt: ""

    },
    {
        Name: "Chemical And Fertilize",
        Image: Indu2,

        alt: "data 1"
    },

    {
        Name: "Oil And Gas Industries",
        Image: Indu3,

        alt: "data 1"

    },
    {
        Name: "Pharmaceuticals",
        // Image: 'Img/indu4.png',
        Image: Indu4,

        alt: "data 1"
    },
    {
        Name: "Power Industries",
        Image: Indu5,
        alt: "data 1"
    },
    {
        Name: "Metal Industries",
        Image: Indu6,
        alt: "data 1"
    },
    {
        Name: "Sugar Mills",
        Image: Indu7,
        alt: "data 1"
    },
    {
        Name: "Paper Industries",
        Image: Indu8,
        alt: "data 1"
    },
    {
        Name: "Medical Industries",
        Image: Indu9,
        alt: "data 1"
    }
]
export default info