import React from "react";

function ProjectHead() {
  return (
    <>
      <div>
        <p>Projects</p>
      </div>
    </>
  );
}

export default ProjectHead;
