import React from "react";
import Fram from "../../assets/Img/Fram.png"
function Producthead() {
  return (
    <div id="productimgbox">
      <img src={Fram} alt="fram" />
    </div>
  );
}

export default Producthead;
