import React from "react";
import FooterDegin from "../../Component/Footer/FooterDegin";

import Blog from "../../Component/Blog/Blog";

function BlogDegine() {
  return (
    <>
      <section className="Blog">
        <Blog />
      </section>
      <FooterDegin />
    </>
  );
}

export default BlogDegine;
