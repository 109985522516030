import React from 'react'
import ErrorComp from '../../Component/Error/ErrorComp'
import FooterDegin from '../../Component/Footer/FooterDegin'
import Success from '../../Pages/404/success'

function ErrorDegine() {
  return (
  <>
  <ErrorComp/>
  <FooterDegin/>
  </>
  )
}

export default ErrorDegine