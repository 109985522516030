import React from 'react'
import ErrorDegine from '../../Degines/Error/ErrorDegine'
// import Success from './success'


function Error() {
  return (
 <>
 <ErrorDegine/>
 {/* <Success/> */}


 </>
  )
}

export default Error
